@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
    @font-face {
      font-family: 'chakra-petch';
      font-display: block;
      src: url('/public/fonts/Chakra_Petch/ChakraPetch-Regular.ttf') format('truetype'),
            url('/public/fonts/Chakra_Petch/ChakraPetch-Regular.woff') format('woff');
    }
    @font-face {
      font-family: 'chakra-petch-bold';
      font-display: block;
      src: url('/public/fonts/Chakra_Petch/ChakraPetch-Bold.ttf') format('truetype'),
            url('/public/fonts/Chakra_Petch/ChakraPetch-Bold.woff') format('woff');
    }
    @font-face {
      font-family: 'chakra-petch-medium';
      font-display: block;
      src: url('/public/fonts/Chakra_Petch/ChakraPetch-Medium.ttf') format('truetype'),
            url('/public/fonts/Chakra_Petch/ChakraPetch-Medium.woff') format('woff');
    }
    @font-face {
      font-family: 'chakra-petch-light';
      font-display: block;
      src: url('/public/fonts/Chakra_Petch/ChakraPetch-Light.ttf') format('truetype'),
            url('/public/fonts/Chakra_Petch/ChakraPetch-Light.woff') format('woff');
    }
    @font-face {
      font-family: 'roboto-mono';
      font-weight: 400;
      font-display: block;
      src: url('/public/fonts/Roboto_Mono/RobotoMono-VariableFont_wght.ttf') format('truetype'),
            url('/public/fonts/Roboto_Mono/RobotoMono-VariableFont_wght.woff') format('woff');
    }
    @font-face {
      font-family: 'roboto-mono-bold';
      font-weight: 600;
      font-display: block;
      src: url('/public/fonts/Roboto_Mono/RobotoMono-VariableFont_wght.ttf') format('truetype'),
            url('/public/fonts/Roboto_Mono/RobotoMono-VariableFont_wght.woff') format('woff');
    }
    @font-face {
      font-family: 'roboto-mono-light';
      font-weight: 200;
      font-display: block;
      src: url('/public/fonts/Roboto_Mono/RobotoMono-VariableFont_wght.ttf') format('truetype'),
            url('/public/fonts/Roboto_Mono/RobotoMono-VariableFont_wght.woff') format('woff');
    }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
